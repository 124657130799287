import { TextLink } from '@troon/ui';

export default function VisaInfinitePrivilege() {
	return (
		<div class="flex flex-col gap-8 md:mt-24">
			<img
				src="https://images.ctfassets.net/rdsy7xf5c8dt/4N51IXCq77mWLykft0HfPd/bdd6d0b85072def56acc5ffb82f18424/Visa_Infinite_Privilege_Logo.svg"
				alt="Visa Infinite Privilege®"
				width={96}
				height={42}
				class="mx-auto w-full max-w-64"
			/>

			<p>
				As a Visa Infinite Privilege® cardholder, you have access to two great benefits with Troon Golf, so you can
				experience new courses on your next getaway.
			</p>

			<p>
				<TextLink href="/rewards/visa/visa-infinite-privilege-ca/terms">Terms and Conditions</TextLink>
			</p>
		</div>
	);
}
